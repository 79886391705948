const scrollTo = {
  hash: '',
  offset: 0,
  activeEl: '',
  el: '',
  init() {
    this.el = $q('[data-scroll]')
    this.offset = window.innerWidth > 576 ? 100 : 0
    this.hash = window.location.hash

    if (!this.el.first) return

    this.urlHash()
    this.listen()
  },
  urlHash() {
    if (!this.hash) return
    this.move(this.hash)
  },
  listen() {
    this.el.listen(
      'click',
      btn => {
        const goTo = btn.dataset.scroll != '' ? btn.dataset.scroll : btn.getAttribute('href')
        this.move(goTo)

        window.history.pushState('', '', goTo)
      },
      true
    )

    $q('window').listen('scroll', w => {
      $q('.blocks').loop(row => {
        if (w.pageYOffset - (w.innerHeight + this.offset) > row.offsetTop) {
          if (this.activeEl !== row) {
            this.el.loop(btn => btn.classList.remove('active'))
            this.activeEl = row

            $q(`a[href="#${row.id}"]`, btn => btn.classList.add('active'))
          }
        }
      })
    })
  },
  move(goTo) {
    const top = $q(goTo).first.getBoundingClientRect().top + (window.pageYOffset - this.offset)
    window.scrollTo({ top, behavior: 'smooth' })
  }
}

export default scrollTo
