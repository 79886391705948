import axios from 'axios'

const suggestionForm = {
  init() {
    $q('.file-suggestion', suggestion => {
      suggestion.onchange = () => {
        const formData = new FormData()

        formData.append('action', 'suggestion_form_helper')
        formData.append('_ajax_nonce', myAjax.nonce)
        formData.append('suggestion', suggestion.files[0])

        $q('.wpcf7-form').first.classList.add('submitting')
        $q('.wpcf7-submit').first.disabled = true

        axios
          .post(myAjax.ajaxurl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(function (response) {
            const wpResponse = response.data.success

            if (!wpResponse) {
              console.error('There was a mistake')
              return false
            }

            const wpData = response.data.data

            $q(`#${suggestion.name}-url`).first.value = wpData.uploadPathUrl
            $q('.wpcf7-form').first.classList.remove('submitting')
            $q('.wpcf7-submit').first.disabled = ''
          })
      }
    })
  }
}

export default suggestionForm
