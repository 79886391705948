// Lib
import './lib/q'

// Bootstrap
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/tab'

// Views
import glob from './views/glob'
import home from './views/home'

class global {
  constructor() {
    window.notification = ''
  }

  start() {
    glob.init()
    home.init()
  }

  onLoad() {
    document.body.classList.add('loaded')
    glob.load()
    home.load()
  }

  preLoad() {}

  loadComplete() {}
}
const app = new global()

// Window Events
window.addEventListener('DOMContentLoaded', () => app.start(), false)
window.addEventListener(
  'load',
  () => {
    setTimeout(() => {
      app.onLoad()
      app.loadComplete()
    }, 1000)
  },
  false
)
window.addEventListener(
  'scroll',
  () => {
    const scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop

    if (scrollTop > 0) document.body.classList.add('has-scrolled')
    else document.body.classList.remove('has-scrolled')
  },
  false
)
