import '../../../../../node_modules/@glidejs/glide/dist/css/glide.core.css'
import '../../../../../node_modules/@glidejs/glide/dist/css/glide.theme.css'
import Glide from '@glidejs/glide'

const homeSlider = {
  elHomeSlider: '',
  elIntroSlider: '',
  init() {
    this.elHomeSlider = $q('.home-slider')
    this.elIntroSlider = $q('.intro-slider')

    this.homeSlider()
    this.introSlider()
  },
  homeSlider() {
    if (!this.elHomeSlider.first) return

    new Glide(this.elHomeSlider.first, {
      type: 'carousel',
      autoplay: 6000,
      hoverpause: false,
      gap: 0
    }).mount()
  },

  introSlider() {
    if (!this.elIntroSlider.first) return

    new Glide(this.elIntroSlider.first, {
      type: 'carousel',
      perView: 4,
      breakpoints: {
        1399: {
          perView: 3
        },
        1200: {
          perView: 2
        },
        768: {
          perView: 1
        }
      }
    }).mount()
  }
}
export default homeSlider
