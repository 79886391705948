const tables = {
  elTables: '',
  init() {
    this.elTables = $q('body.single-programs table')

    if (!this.elTables.first) return

    this.elTables.loop(table => {
      table.classList.add('table')
      table.classList.add('table-bordered')
      table.classList.add('table-light')
    })
  }
}

export default tables
