// Views

// Components
import applicantForm from '../components/applicantForm'
import scrollTo from '../components/scrollTo'
import suggestionForm from '../components/suggestionForm'
import tables from '../components/tables'

// Class

const glob = {
  init() {
    applicantForm.init()
    scrollTo.init()
    tables.init()
    suggestionForm.init()
  },
  load() {}
}
export default glob
