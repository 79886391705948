/**
 * Q.
 * Tiny Dom Selector Engine
 *
 * @author Dimitris Tahos
 * @since	v1.0.0  Thursday, October 29th, 2020.
 * @version	v1.0.6	Monday, December 7th, 2020.
 * @global
 */
class Q {
  constructor($element, $callback = false) {
    this.element = $element
    this.query = []
    this._type = 'window'

    this.query = this.element === 'window' ? window : document.querySelectorAll(this.element)

    if ($element !== 'window') {
      this._type = 'domElement'
      this.loop($callback)
    }
  }

  /**
   * Looping The Query
   *
   * @param {*} $callback
   */
  loop($callback = false) {
    if (!$callback || this._type !== 'domElement') return
    for (let $i = 0; $i < this.query.length; $i++) $callback(this.query[$i], $i)
  }

  /**
   * Retrieve Whole Query
   */
  list() {
    return this.query
  }

  /**
   * Retrive Specific Value From Query
   *
   * @param {*} $i
   */
  single($i = 0) {
    return this._type === 'domElement' ? this.query[$i] : null
  }

  /**
   * Retrieve First Value From Query
   */
  first() {
    return this.single() ? this.single() : null
  }

  /**
   * Retrieve Parent Element Value From Query
   */
  parent() {
    return this.single() ? this.single().parentNode : null
  }

  /**
   * Retrieve All Child Nodes From Query
   */
  children() {
    return this.single() ? this.single().childNodes : null
  }

  /**
   * Retrieve First Child Node From Query
   */
  child() {
    return this.single() ? this.single().childNodes[0] : null
  }

  /**
   * Retrieve Html From Query
   */
  html() {
    return this.single() ? this.single().innerHTML : null
  }

  /**
   * Adds Event Listener To Queried Object
   *
   * @param {*} $type
   * @param {*} $callback
   * @param {*} $prevent
   */
  listen($type, $callback = false, $prevent = false) {
    return this._type === 'domElement' ?
      this.loop($item => this.eventListen($item, $type, $callback, $prevent)) :
      this.eventListen(this.query, $type, $callback, $prevent)
  }

  eventListen($item, $type, $callback = false, $prevent = false) {
    $item.addEventListener(
      $type,
      event => {
        $prevent ? event.preventDefault() : ''
        $callback ? $callback($item, event) : ''
      },
      false
    )
  }
}

/**
 * Global Api
 *
 * @param {*} $element
 * @param {*} $callback
 */
const qGlobal = ($element, $callback = false) => {
  const query = new Q($element, $callback)

  const methods = {
    list: query.list(),
    first: query.first(),
    parent: query.parent(),
    child: query.child(),
    html: query.html(),
    loop: $method => query.loop($method),
    single: $i => query.single($i),
    listen: ($type, $method = false, $prevent = false) => query.listen($type, $method, $prevent)
  }

  return methods
}

window.$q = qGlobal
