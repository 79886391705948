// Views

import homeSlider from '../components/home/slider'

// Components

// Class

const glob = {
  init() {},
  load() {
    homeSlider.init()
  }
}
export default glob
